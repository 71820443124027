import {
  DocumentFieldWithValue,
  DocumentVersion,
  DocumentVersionAnalyzeFlagsRequest,
  DocumentVersionCreateFieldValueRequest,
  DocumentVersionFileUploadRequest,
} from '@/models/api'
import { API_DOCUMENTS_ROUTE_PATH } from '@/constants/api'

import BaseApi from './base'
import { apiClient } from './client'

export class DocumentVersionsApi extends BaseApi {
  constructor() {
    super(API_DOCUMENTS_ROUTE_PATH)
    this.resourcePath = 'document-versions'
    this.asChildPath = 'versions'
  }

  async getFields(
    documentVersionId: string,
    {
      signal,
    }: {
      signal?: AbortSignal
    } = {},
  ) {
    return apiClient.get<DocumentFieldWithValue[]>(
      this.resourcePath,
      `${documentVersionId}/fields`,
      {
        signal,
      },
    )
  }

  async createInfoValue({
    documentVersionId,
    fieldId,
    value,
  }: DocumentVersionCreateFieldValueRequest): Promise<DocumentFieldWithValue> {
    return apiClient.post<DocumentFieldWithValue>(
      `${this.resourcePath}/${documentVersionId}/fields/${fieldId}/values`,
      { value },
    )
  }

  async generateHtml(documentVersionId: string) {
    return apiClient.post(
      `${this.resourcePath}/${documentVersionId}/generate-html`,
    )
  }

  async runFlagsAnalysis({
    documentId,
    documentVersionId,
    ...payload
  }: DocumentVersionAnalyzeFlagsRequest) {
    return apiClient.post(
      `${this.getNestedPath(documentId)}/${documentVersionId}/analyze-flags`,
      payload,
    )
  }

  async uploadNewDocumentVersionFile({
    documentId,
    ...payload
  }: DocumentVersionFileUploadRequest) {
    return apiClient.post<DocumentVersion>(
      `${this.getNestedPath(documentId)}/upload`,
      payload,
    )
  }
}

const documentVersionsApi = new DocumentVersionsApi()

export default documentVersionsApi
