import { useCallback } from 'react'
import { QueryFilters, useQueryClient } from '@tanstack/react-query'

import { QKEY } from '@/constants/queryKeys'

export function useInvalidateFavoritesNavigation() {
  const queryClient = useQueryClient()

  return useCallback(() => {
    const filters: QueryFilters = {
      queryKey: QKEY.VIEWS_NAVIGATION.getFavoritesNavigation,
    }

    queryClient.cancelQueries(filters)
    queryClient.invalidateQueries(filters)
  }, [queryClient])
}
