import { createContext } from 'react'

import { useDocumentVersion } from '@/hooks/queries/useDocumentVersion'
import { VersionedDocument } from '@/models/api'
import { noop } from '@/utils'

export const DocumentContext = createContext<{
  documentId?: string
  versionNumber?: number
  document?: VersionedDocument | null
  latestVersionNumber?: number
  isLatestVersion: boolean
  documentVersion?: ReturnType<typeof useDocumentVersion>
  onVersionCreated(): void
  isLoading: boolean
  isArchived: boolean
  refetchDocument(): void
}>({
  isLatestVersion: false,
  onVersionCreated: noop,
  isLoading: false,
  isArchived: false,
  refetchDocument: noop,
})
