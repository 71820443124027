import React, { useCallback, useMemo, useState } from 'react'

import { useSelectedAccount } from '@/hooks/useSelectedAccount'
import {
  useUploadDocumentFiles,
  useUploadNewDocumentVersionFile,
} from '@/hooks/mutations'
import { UIAlert, UIModal, useToast } from '@/components/ui'
import { useUser } from '@/hooks/queries/useUser'
import {
  SupportLink,
  UploadcareFileUploaderInline,
  UploadedFileEntry,
  useUploaderCtxProvider,
} from '@/components/shared'
import { DocumentFileType } from '@/models/enums'

interface UploadFilesDialogProps {
  isOpen: boolean
  onChange: (isOpen: boolean) => void
  onClose(): void
  title?: string
  description?: string
  isSingleUpload: false
}

interface SingleUploadFilesDialogProps {
  isOpen: boolean
  onChange: (isOpen: boolean) => void
  onClose(): void
  title?: string
  description?: string
  isSingleUpload: true
  metadata: {
    documentId: string
  }
  allowedFormats: DocumentFileType[]
  onSuccess(): void
}

const UploadFilesDialog = ({
  isOpen,
  onChange,
  onClose,
  title,
  description,
  isSingleUpload,
  ...props
}: UploadFilesDialogProps | SingleUploadFilesDialogProps) => {
  const [isUploading, setIsUploading] = useState(false)

  const { ctxProviderRef } = useUploaderCtxProvider()

  const selectedAccount = useSelectedAccount()

  const {
    mutateAsync: uploadDocumentFiles,
    isPending,
    error: uploadDocumentFilesError,
  } = useUploadDocumentFiles()

  const {
    mutateAsync: uploadDocumentVersionFile,
    isPending: isUploadingVersionFile,
  } = useUploadNewDocumentVersionFile()

  const { data: user } = useUser()

  const { toast } = useToast()

  const uploaderMetadata = useMemo(
    () => ({
      userId: user?.id || '',
      accountId: selectedAccount?.id || '',
    }),
    [user, selectedAccount],
  )

  const isPdfAllowed =
    !(props as SingleUploadFilesDialogProps).allowedFormats ||
    (props as SingleUploadFilesDialogProps).allowedFormats.includes(
      DocumentFileType.PDF,
    )

  const showSuccessUploadToast = useCallback(
    (count = 1) => {
      toast.success(count > 1 ? `${count} files uploaded` : 'File uploaded')
    },
    [toast],
  )

  const showFailedUploadToast = useCallback(
    (count = 1) => {
      toast.error(
        count > 1 ? `${count} file uploads failed` : 'File upload failed',
      )
    },
    [toast],
  )

  const onFilesUploadedSuccessHandler = useCallback(
    async (successEntries: UploadedFileEntry[]) => {
      const files = successEntries.map(({ name, uuid, cdnUrl }) => ({
        originalName: name,
        externalId: uuid,
        url: cdnUrl,
        tags: [],
      }))

      if (!files.length) return

      if (isSingleUpload) {
        try {
          await uploadDocumentVersionFile({
            documentId: (props as SingleUploadFilesDialogProps).metadata
              .documentId,
            file: files[0],
          })

          setIsUploading(false)
          showSuccessUploadToast()

          const { onSuccess } = props as SingleUploadFilesDialogProps
          onSuccess()
        } catch (error) {
          console.error('Error uploading single file', error)
          showFailedUploadToast()
        }
      } else {
        const uploadResults = await uploadDocumentFiles({ files })
        if (uploadResults) {
          const {
            successCount: successDocumentsCount,
            failedCount: failedDocumentsCount,
          } = uploadResults

          if (successDocumentsCount) {
            showSuccessUploadToast(successDocumentsCount)
          }

          if (failedDocumentsCount) {
            showFailedUploadToast(failedDocumentsCount)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      showFailedUploadToast,
      uploadDocumentFiles,
      showSuccessUploadToast,
      isSingleUpload,
      uploadDocumentVersionFile,
    ],
  )

  const onFilesUploadedFailedHandler = useCallback(
    (count: number) => {
      showFailedUploadToast(count)
    },
    [showFailedUploadToast],
  )

  // TODO: Need to update core NextUI Modal component to latest version
  return (
    <UIModal
      scrollBehavior="outside"
      placement="center"
      contentClassName="max-w-full sm:max-w-fit sm:min-w-[500px] overflow-y-hidden"
      headerClassName="pt-7 pb-3"
      title={
        <div className="flex w-full flex-col items-center gap-1">
          <div className="text-2xl font-bold">{title}</div>
          <div className="text-small font-light text-default-500">
            {description}
          </div>
        </div>
      }
      isOpen={isOpen}
      onOpenChange={onChange}
    >
      <UploadcareFileUploaderInline
        multiple={!isSingleUpload}
        metadata={uploaderMetadata}
        isDisabled={isUploading || isPending || isUploadingVersionFile}
        contextProviderRef={ctxProviderRef}
        onUploading={() => {
          setIsUploading(true)
        }}
        onFinished={() => {
          !isSingleUpload && setIsUploading(false)
        }}
        onFilesUploadedSuccess={onFilesUploadedSuccessHandler}
        onFilesUploadedFailed={onFilesUploadedFailedHandler}
        onDone={onClose}
        allowedFormats={(props as SingleUploadFilesDialogProps).allowedFormats}
      />

      {isPdfAllowed && (
        <div className="mt-4 text-center text-tiny text-default-400">
          (Clause analysis is unavailable for PDF)
        </div>
      )}

      {!!uploadDocumentFilesError && (
        <div className="pt-4 w-full">
          <UIAlert
            color="error"
            title="Upload Failed"
            message={
              <span>
                There was an error uploading the document(s). Please contact
                support by emailing <SupportLink />
              </span>
            }
          />
        </div>
      )}
    </UIModal>
  )
}

export default UploadFilesDialog
