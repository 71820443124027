import { useMutation, useQueryClient } from '@tanstack/react-query'

import { QKEY } from '@/constants/queryKeys'
import { DocumentVersionFileUploadRequest } from '@/models/api'
import { documentVersionsApi } from '@/services/api'

export function useUploadNewDocumentVersionFile() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: QKEY.DOCUMENTS.uploadNewVersionFile,
    mutationFn: (params: DocumentVersionFileUploadRequest) =>
      documentVersionsApi.uploadNewDocumentVersionFile(params),
    onSuccess: async (_data, { documentId }) => {
      queryClient.invalidateQueries({
        queryKey: QKEY.DOCUMENT_VERSIONS.getAll(documentId),
      })

      const keys = [QKEY.DOCUMENTS.getAll, QKEY.DOCUMENTS_STATS]

      const invalidations = keys.map(key =>
        queryClient.invalidateQueries({
          queryKey: key,
        }),
      )
      await Promise.all(invalidations)
    },
  })
}
