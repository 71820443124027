import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { QKEY } from '@/constants/queryKeys'
import { useDocument } from '@/hooks/queries/useDocument'
import { useDocumentVersion } from '@/hooks/queries/useDocumentVersion'
import { useDocumentVersions } from '@/hooks/queries/useDocumentVersions'

import { DocumentContext } from './documentContext'

export function DocumentContextProvider({ children }: PropsWithChildren) {
  const queryClient = useQueryClient()

  const [documentId, setDocumentId] = useState<string>()

  const [versionNumber, setVersionNumber] = useState<number>()

  const router = useRouter()

  useEffect(() => {
    const documentId = router.query.id
    const versionNumber = router.query.version

    setDocumentId(undefined)
    setVersionNumber(undefined)

    if (documentId && typeof documentId === 'string') {
      setDocumentId(documentId)
    }

    const _versionNumber = parseInt(versionNumber as string)
    if (!isNaN(_versionNumber)) {
      setVersionNumber(_versionNumber)
    }
  }, [router.query.id, router.query.version, setDocumentId])

  const versions = useDocumentVersions(documentId)
  useEffect(() => {
    if (documentId && !versionNumber && versions.data) {
      router.push(`/document/${documentId}/${versions.data[0].versionNumber}`)
    }
  }, [versions, versionNumber, router, documentId])

  const {
    data: document,
    isLoading: isLoadingDocument,
    isFetching: isFetchingDocument,
    refetch: refetchDocument,
  } = useDocument(documentId)

  const documentVersion = useDocumentVersion(documentId, versionNumber)

  const latestVersionNumber = useMemo(
    () => versions.data?.[0]?.versionNumber,
    [versions.data],
  )

  const isLatestVersion = useMemo(
    () => versionNumber === latestVersionNumber,
    [versionNumber, latestVersionNumber],
  )

  const isLoading = useMemo(
    () =>
      versions.isLoading ||
      documentVersion.isLoading ||
      versions.isFetching ||
      documentVersion.isFetching ||
      isLoadingDocument ||
      isFetchingDocument,
    [
      versions.isLoading,
      documentVersion.isLoading,
      versions.isFetching,
      documentVersion.isFetching,
      isLoadingDocument,
      isFetchingDocument,
    ],
  )

  const isArchived = useMemo(() => document?.archived ?? false, [document])

  const onVersionCreated = useCallback(() => {
    // TODO: Should use optimistic update to avoid big refetching
    queryClient.invalidateQueries({ queryKey: QKEY.DOCUMENT(documentId) })
    queryClient.invalidateQueries({
      queryKey: QKEY.DOCUMENT_VERSIONS.getAll(documentId),
    })
    queryClient.invalidateQueries({ queryKey: QKEY.DOCUMENTS.getAll })
    queryClient.invalidateQueries({
      queryKey: QKEY.DOCUMENT_REVISIONS.getAll(documentId),
    })
    queryClient.invalidateQueries({ queryKey: QKEY.DOCUMENTS_STATS })
    queryClient.invalidateQueries({
      queryKey: QKEY.DOCUMENT_EDITOR_DATA(documentId, versionNumber),
    })
    queryClient.invalidateQueries({ queryKey: QKEY.DOCUMENTS.get(documentId) })
    documentVersion.refetch()
  }, [documentId, queryClient, versionNumber, documentVersion])

  return (
    <DocumentContext.Provider
      value={{
        document,
        documentId,
        versionNumber,
        documentVersion,
        latestVersionNumber,
        isLatestVersion,
        onVersionCreated,
        isLoading,
        isArchived,
        refetchDocument,
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}
