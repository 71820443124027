import { Metadata, OutputCollectionState } from '@uploadcare/blocks'
import {
  FileUploaderInline,
  type UploadCtxProvider,
} from '@uploadcare/react-uploader'
import '@uploadcare/react-uploader/core.css'
import React, { useCallback, useEffect } from 'react'

import {
  UPLOADER_ACCEPTED_MIME_TYPES,
  UPLOADER_ALLOWED_FORMATS,
  UPLOADER_ALLOWED_SOURCES_LIST,
  UPLOADER_MAX_UPLOAD_SIZE_BYTES,
  UPLOADER_MAX_UPLOAD_SIZE_MB,
} from '@/constants'
import { cnConcat } from '@/utils'
import { DocumentFileType } from '@/models/enums'

import {
  UploadedFileEntry,
  commonLocaleDefinitionOverride,
  injectValidationsTextInDropArea,
  removeValidationsTextFromDropArea,
} from './common'
import useUploaderCtxProvider from './useUploaderCtxProvider'

export interface UploadcareFileUploaderInlineProps {
  isDisabled?: boolean
  contextProviderRef?: React.RefObject<InstanceType<UploadCtxProvider>>
  onFilesUploadedSuccess(files: UploadedFileEntry[]): void
  onFilesUploadedFailed(count: number): void
  onUploading?(): void
  onFinished?(): void
  onDone?(): void
  metadata?: Metadata
  multiple?: boolean
  allowedFormats?: DocumentFileType[]
}

const UploadcareFileUploaderInline = ({
  isDisabled = false,
  contextProviderRef,
  onUploading,
  onFinished,
  onDone,
  metadata,
  onFilesUploadedFailed,
  onFilesUploadedSuccess,
  multiple = true,
  allowedFormats = UPLOADER_ALLOWED_FORMATS,
}: UploadcareFileUploaderInlineProps) => {
  const { ctxProviderRef, resetUploaderState, uploadedFiles } =
    useUploaderCtxProvider({
      ref: contextProviderRef,
    })

  const onChangeHandler = useCallback(
    async (event: OutputCollectionState) => {
      const { status, successCount, successEntries, failedCount } = event

      if (status === 'success' || status === 'failed') {
        if (successCount) {
          if (!successEntries.length) return

          let filesToAdd = successEntries
          const successEntriesUuids = successEntries.map(
            entry => entry.fileInfo.uuid,
          )
          if (uploadedFiles.current?.length > 0) {
            filesToAdd = successEntries.filter(
              entry => !uploadedFiles.current.includes(entry.fileInfo.uuid),
            )

            uploadedFiles.current = Array.from(
              new Set([...uploadedFiles.current, ...successEntriesUuids]),
            )
          } else {
            uploadedFiles.current = successEntriesUuids
          }

          onFilesUploadedSuccess(filesToAdd)
        }

        if (failedCount) onFilesUploadedFailed(failedCount)

        onFinished?.()
      } else if (status === 'uploading') {
        onUploading?.()
      }
    },
    [
      onFilesUploadedFailed,
      onFilesUploadedSuccess,
      onFinished,
      onUploading,
      uploadedFiles,
    ],
  )

  useEffect(() => {
    injectValidationsTextInDropArea(
      allowedFormats.join(', '),
      UPLOADER_MAX_UPLOAD_SIZE_MB.toString(),
    )

    return () => removeValidationsTextFromDropArea()
  }, [allowedFormats])

  const acceptedTypes = UPLOADER_ACCEPTED_MIME_TYPES(allowedFormats)

  return (
    <div className="space-y-3">
      <FileUploaderInline
        removeCopyright
        confirmUpload
        checkForUrlDuplicates
        multiple={multiple}
        store={false}
        imgOnly={false}
        className={cnConcat(
          'file-uploader-wrapper',
          isDisabled && 'pointer-events-none opacity-disabled',
        )}
        localeDefinitionOverride={commonLocaleDefinitionOverride}
        pubkey={process.env.NEXT_PUBLIC_UPLOAD_CARE_PUBLIC_KEY}
        apiRef={ctxProviderRef}
        sourceList={UPLOADER_ALLOWED_SOURCES_LIST}
        maxLocalFileSizeBytes={UPLOADER_MAX_UPLOAD_SIZE_BYTES}
        accept={acceptedTypes}
        externalSourcesPreferredTypes={acceptedTypes}
        metadata={metadata}
        onDoneClick={onDone}
        onChange={onChangeHandler}
        onModalClose={resetUploaderState}
      />
    </div>
  )
}

export default UploadcareFileUploaderInline
