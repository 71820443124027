import { DocumentFileType } from '@/models/enums'
import { fileTypes } from './fileTypes'

export const UPLOADER_ALLOWED_SOURCES_LIST =
  'local, url, gdrive, dropbox, onedrive'

export const UPLOADER_ALLOWED_FORMATS = [
  DocumentFileType.PDF,
  DocumentFileType.DOCX,
]

export const UPLOADER_ACCEPTED_MIME_TYPES = (types: DocumentFileType[]) =>
  types
    .map(format => `${fileTypes[format]},${fileTypes[format]}; charset=utf-8`)
    .join(',')

export const UPLOADER_MAX_UPLOAD_SIZE_MB = 30

export const UPLOADER_MAX_UPLOAD_SIZE_BYTES =
  UPLOADER_MAX_UPLOAD_SIZE_MB * 1024 * 1024

export const UPLOADER_MAX_TOTAL_UPLOAD_FILES = 100
